
export default {
    data() {
      return {
        loading: false,
        TableLoading:false,
        selectedRole: [],
        selectedDistrict: '',
        selectedVillage: '',
//selectedProvince:'',
        listDistricts:[],
    //  listProvinces:[],
        listVillages:[],

        filterVillages:[],

        server_errors: {
          email: '',
          roleId:''
        },
        village:{},
        villageID:'',
        village_edit: {},
        headers: [
          {
            text: "ID",
            align: "start",
            value: "id",
            sortable: false
          },
          {
            text: "ບ້ານ",
            align: "start",
            value: "name",
            sortable: false
          },
          {
            text: "ເມືອງ",
            align: "start",
            value: "distName",
          },
          { text: "ແຂວງ", value: "proName" },
          { text: "Actions", value: "actions", sortable: false },
        ],
        ruleDistrict: [(v) => !!v || 'District is required',],
        ruleVillage: [(v) => !!v || 'Village is required',],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        nameRules: [
          v => !!v || 'Name is required',
          v => (v && v.length >= 2) || 'Name must be less than 2 characters',
        ],

          ຈຸດສັງເກດRules: [
            v => !!v || 'Name is required',
            v => (v && v.length >= 2) || 'Name must be less than 2 characters',
          ],
        toast: {
          value: true,
          color: 'success',
          msg: 'Success'
        },
        toast_error: {
          value: true,
          color: 'error',
          msg: 'Something when wrong!'
        }

      }
    },
    methods: {
      OpenModalAdd() {
        this.$store.commit("modalAdd_State", true);
      },
      AddItem() {
        if (this.$refs.form.validate() == true) {
          this.SubmitItem();
        }
      },

      SubmitItem() {
        this.village.district_id = this.selectedDistrict;
        this.loading = true;
        this.$admin.post('add-village', this.village)
          .then(res => {
            if (res.data.success == true) {
              setTimeout(() => {
                this.loading = false;
                this.CloseModalAdd();
                this.village = {};
                this.getData();
                this.$store.commit("Toast_State", this.toast);
              }, 300);
            }
          }).catch(error => {
            this.loading = false;
            this.$store.commit("Toast_State", this.toast_error);
            this.getData();
          //  this.reset();
            if (error.response.status == 422) {
              var obj = error.response.data.errors;
              for (let [key, village] of Object.entries(obj)) {
                this.server_errors[key] = village[0];
              }
            }
          });
      },

      CloseModalAdd() {
        this.village = {};
        this.reset();
        this.getData();
        this.$store.commit("modalAdd_State", false);
      },
      OpenModalEdit(item) {
        this.village_edit = item;
        this.$store.commit("modalEdit_State", true);
      },

      UpdateItem() {
        if (this.$refs.form.validate() == true) {
          this.loading = true;
          this.$admin.post('edit-village/' + this.village_edit.id,
            this.village_edit)
            .then(res => {
              if (res.data.success == true) {
                setTimeout(() => {
                  this.loading = false;
                  this.CloseModalEdit();
                  this.village_edit = {};
                  this.reset();
                  this.getData();
                  this.$store.commit("Toast_State", this.toast);
                }, 300);
              }
            }).catch(error => {
              this.loading = false;
              this.$store.commit("Toast_State", this.toast_error);
              this.getData();
              if (error.response.status == 422) {
                var obj = error.response.data.errors;
                for (let [key, village] of Object.entries(obj)) {
                  this.server_errors[key] = village[0];
                }
              }
            });
        }
      },

      CloseModalEdit() {
        this.reset();
        this.village_edit = {};
        this.getData();
        this.$store.commit("modalEdit_State", false);
      },

      getData() {
        this.TableLoading = true;
        this.$admin.get('list-address').then(res => {
         this.listDistricts = res.data.districts;
         this.filterVillages = res.data.listVillages;
          this.listVillages = res.data.listVillages;
            this.TableLoading =false;
          }).catch(() => {
            this.TableLoading =false;
            this.$store.commit("Toast_State",this.toast_error);
          });
      },

  //   FilterVillages(districtId){
  //     const result_checking = this.filterVillages.filter(item=>{
  //          return item.distId == districtId;
  //     });
  //     this.listVillages = result_checking;
  //     this.selectedVillage = {...this.listVillages[0]};

  // },


  // FilterProvince(proId){
  //   const result_checking = this.filterDistricts.filter(item=>{
  //        return item.province_id == proId;
  //   });
  //   this.listDistricts = result_checking;
  //   this.selectedDistrict = {...this.listDistricts[0]};
  // },

      closeDelete() {
        this.$store.commit("modalDelete_State", false);
      },

      deleteItem(id) {
        this.villageID = id;
        this.$store.commit("modalDelete_State", true);
      },

      deleteItemConfirm() {
        this.loading = true;
        this.$admin.delete('delete-village/' + this.villageID).then(res => {
          if (res.data.success == true) {
            setTimeout(() => {
              this.getData();
              this.$store.commit("modalDelete_State", false);
              this.loading = false;
              this.$store.commit("Toast_State", this.toast);
            }, 300);
          }
        }).catch(() => {
          this.getData();
          this.$store.commit("Toast_State", this.toast_error);
          this.$store.commit("modalDelete_State", false);
          this.loading = false;
        })
      },
       reset() {
        this.$refs.form.reset();
      },

    },
    watch: {

        // 'selectedProvince':function(pro_id){
        // this.FilterProvince(pro_id);

        // },

      'factory.email': function () {
        this.server_errors.email = '';
      },
      'factory_edit.user_email': function () {
        this.server_errors.email = '';
      },
    },
    created() {
      this.getData();
    },
  }
